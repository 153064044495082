import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: 'licenses',
    meta: {
      breadcrumb: {
        key: 'license.entity',
        plural: 2
      }
    },
    children: [
      {
        path: '',
        name: 'licenses.index',
        component: () => import('@/modules/licenses/views/LicensesIndex.vue')
      },
      {
        path: ':licenseId',
        name: 'licenses.show',
        props: true,
        component: () => import('@/modules/licenses/views/LicensesShow.vue')
      }
    ]
  }
]

export default routes
